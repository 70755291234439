import React from 'react';
import styled from 'styled-components';
import { Container, Section } from '../../components/global';
import Layout from '../../components/common/layout/layout';
import SEO from '../../components/common/layout/seo';
import Navigation from '../../components/common/navigation/navigation';
import Footer from '../../components/sections/footer';

const SecurityPage = () => {
  return (
    <Layout>
      <SEO title="Security" />
      <Navigation />
      <StyledSection style={{ marginTop: '66px' }}>
        <SummaryContainer>
          <CardContainer>
            <SummaryTitle>Security</SummaryTitle>
            <CardContent>
              Nice blurb on Auth0, Xero integration, best practices. Nothing that would get us sued.
              Blurb out some stuff about role based security in the app Agents see their deals only
              if configured that way
            </CardContent>
          </CardContainer>
        </SummaryContainer>
      </StyledSection>
      <Footer />
    </Layout>
  );
};

export default SecurityPage;

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  @media (max-width: ${(props) => props.theme.screen.md}) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const CardContainer = styled.div`
  width: 500px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 700px;
    margin-bottom: 20px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 80%;
  }
`;
const CardContent = styled.p`
  line-height: 1.6;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    text-align: center;
  }
`;
const SummaryContainer = styled(Container)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // padding: 80px 0 40px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-direction: column;
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    img {
      width: 90%;
    }
  }
`;

const SummaryTitle = styled.h3`
  color: #01a1e8;
  margin: 0 auto 32px;
  text-align: center;
`;
